export const fetchReceipts = async ({ teamId, test }: { teamId: string; test: boolean }) => {
    let endPoint = 'customerportalapp/v1/data/receipts'
    let url = `${process.env.REACT_APP_INTERNAL_API_URL}/${endPoint}`
    //make a fetch request passing Authorization in the headers
    var token = localStorage.getItem('authToken')

    if (!token) {
        var data = localStorage.getItem(`gigCusPortData-${teamId ?? ''}`) ?? ''
        token = data ? JSON.parse(data)?.token : null
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ test: false }),
    }
    const res = await fetch(url, requestOptions)
    if (res.status >= 200 && res.status < 300) {
        return res.json()
    } else {
        console.log('ERROR')
        throw res.json()
    }
}
