interface InvoiceUsage {
    value: string
    label: string
}

export const invoiceUsage: InvoiceUsage[] = [
    {
        label: 'Adquisición De Mercancías',
        value: 'G01',
    },
    {
        label: 'Devoluciones Descuentos Bonificaciones',
        value: 'G02',
    },
    {
        label: 'Gastos En General',
        value: 'G03',
    },
    {
        label: 'Construcciones',
        value: 'I01',
    },
    {
        label: 'Mobiliario Y Equipo De Oficina',
        value: 'I02',
    },
    {
        label: 'Equipo de Transporte',
        value: 'I03',
    },
    {
        label: 'Equipo de Cómputo y Accesorios',
        value: 'I04',
    },
    {
        label: 'Dados, troqueles, moldes, matrices y herramental.',
        value: 'I05',
    },
    {
        label: 'Comunicaciones Telefónicas',
        value: 'I06',
    },
    {
        label: 'Comunicaciones Satelitales',
        value: 'I07',
    },
    {
        label: 'Otra Maquinaria',
        value: 'I08',
    },
    {
        label: 'Honorarios médicos, dentales y gastos hospitalarios.',
        value: 'D01',
    },
    {
        label: 'Gastos Médicos Por Incapacidad',
        value: 'D02',
    },
    {
        label: 'Gastos Funerales',
        value: 'D03',
    },
    {
        label: 'Donativos',
        value: 'D04',
    },
    {
        label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
        value: 'D05',
    },
    {
        label: 'Aportaciones voluntarias al SAR.',
        value: 'D06',
    },
    {
        label: 'Prima Seguros Gastos Médicos',
        value: 'D07',
    },
    {
        label: 'Gastos de transportación escolar obligatoria',
        value: 'D08',
    },
    {
        label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
        value: 'D09',
    },
    {
        label: 'Pagos por servicios educativos (colegiaturas)',
        value: 'D10',
    },
    {
        label: 'Sin efectos fiscales',
        value: 'S01',
    },
    {
        value: 'CP01',
        label: 'Pagos',
    },
    {
        label: 'Nómina',
        value: 'CN01',
    },
]
