export const fetchMe = async ({ teamId }: { teamId: string }) => {
    let endPoint = 'customerportalapp/v1/data/me'
    let url = `${process.env.REACT_APP_INTERNAL_API_URL}/${endPoint}`
    //make a fetch request passing Authorization in the headers
    let token = localStorage.getItem('authToken')

    if (!token) {
        var data = localStorage.getItem(`gigCusPortData-${teamId ?? ''}`) ?? ''
        token = data ? JSON.parse(data)?.token : null
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ test: false }),
    }

    try {
        const res = await fetch(url, requestOptions)

        let json = await res.json()
        return json
    } catch (error: any) {
        console.log(error.status)
        throw error
    }
}

export const updateMe = async (data: any, teamId: string) => {
    let endPoint = 'customerportalapp/v1/data/update/me'
    let url = `${process.env.REACT_APP_INTERNAL_API_URL}/${endPoint}`
    let token = localStorage.getItem('authToken')

    // Validate the fiscal information
    let validateUrl = `${process.env.REACT_APP_INTERNAL_API_URL}/apiClients/v1/validate/customerPortal`
    const validateRequest = await fetch(validateUrl, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            client: {
                legal_name: data.legalName,
                tax_system: data.tax_system,
                rfc: data.rfc,
                address: {
                    country: data.country,
                    zip: data.zip,
                },
            },
        }),
    })

    if (validateRequest.status !== 200) {
        const validateResJson = await validateRequest.json()
        throw validateResJson
    }

    if (!token) {
        var nd = localStorage.getItem(`gigCusPortData-${teamId ?? ''}`) ?? ''
        token = nd ? JSON.parse(nd)?.token : null
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }

    try {
        const res = await fetch(url, requestOptions)
        let json = await res.json()

        if (res.status !== 200) {
            throw json
        }

        return json
    } catch (error: any) {
        console.log(error.status)
        throw error
    }
}

export const fetchPendingReceipts = async ({ teamId }: { teamId: string }) => {
    let endPoint = 'customerportalapp/v1/data/receipts'
    let url = `${process.env.REACT_APP_INTERNAL_API_URL}/${endPoint}`
    let token = localStorage.getItem('authToken')

    if (!token) {
        let data = localStorage.getItem(`gigCusPortData-${teamId ?? ''}`) ?? ''
        token = data ? JSON.parse(data)?.token : null
    }

    const res = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pending: true }),
    })

    const json = await res.json()
    if (res.status !== 200) {
        throw json
    }

    return json
}

export function getClavesForRegimenFiscal(regimenFiscal: string): string[] {
    const regimenToClaves: Record<string, string[]> = {
        '610': ['S01', 'CP01'],
        '616': ['S01', 'CP01'],
        '608': ['D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10', 'S01', 'CP01'],
        '611': ['D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10', 'S01', 'CP01'],
        '607': ['D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10', 'S01', 'CP01'],
        '615': ['D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10', 'S01', 'CP01'],
        '614': ['D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10', 'S01', 'CP01'],
        '601': ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08', 'S01', 'CP01'],
        '603': ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08', 'S01', 'CP01'],
        '626': ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08', 'S01', 'CP01'],
        '620': ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08', 'S01', 'CP01'],
        '621': ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08', 'S01', 'CP01'],
        '622': ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08', 'S01', 'CP01'],
        '623': ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08', 'S01', 'CP01'],
        '624': ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08', 'S01', 'CP01'],
        '605': ['D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10', 'S01', 'CP01', 'CN01'],
        '606': [
            'G01',
            'G02',
            'G03',
            'I01',
            'I02',
            'I03',
            'I04',
            'I05',
            'I06',
            'I07',
            'I08',
            'D01',
            'D02',
            'D03',
            'D04',
            'D05',
            'D06',
            'D07',
            'D08',
            'D09',
            'D10',
            'S01',
            'CP01',
        ],
        '612': [
            'G01',
            'G02',
            'G03',
            'I01',
            'I02',
            'I03',
            'I04',
            'I05',
            'I06',
            'I07',
            'I08',
            'D01',
            'D02',
            'D03',
            'D04',
            'D05',
            'D06',
            'D07',
            'D08',
            'D09',
            'D10',
            'S01',
            'CP01',
        ],
        '625': [
            'G01',
            'G02',
            'G03',
            'I01',
            'I02',
            'I03',
            'I04',
            'I05',
            'I06',
            'I07',
            'I08',
            'D01',
            'D02',
            'D03',
            'D04',
            'D05',
            'D06',
            'D07',
            'D08',
            'D09',
            'D10',
            'S01',
            'CP01',
        ],
    }

    const regimenKey = String(regimenFiscal)
    return regimenToClaves[regimenKey] || []
}
