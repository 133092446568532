import { Col, Row, Typography, Table, Tag, Input, Form } from 'antd'
import { useState } from 'react'

import { ReadableTeamDef } from '../../context/teamSlice'
import { useSelector } from 'react-redux'
import type { ColumnsType } from 'antd/es/table'
import moment from 'moment-timezone'
import { paymentStatus, paymentStatusColor } from '../../helpers/helperfunctions'
import { useQuery } from 'react-query'
import { fetchPayments } from './paymentshelpers'
import ClientColumn from '../general/clientcolumn'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'

const PaymentTable = () => {
    const { team }: { team: ReadableTeamDef } = useSelector((state: any) => state.team)
    const { me } = useSelector((state: any) => state.data)
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [form] = Form.useForm()

    moment.locale('es')
    //set moment to es_MX
    const { data: pmData, status } = useQuery('payments', () => fetchPayments({ teamId: team?.id }), {
        enabled: true,
    })

    const columns: ColumnsType<any> = [
        {
            title: 'Información fiscal',
            dataIndex: 'customer',
            fixed: 'left',
            render: (text, record) => {
                return <ClientColumn text={text} record={record} />
            },
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            render: (text, record) => {
                return (
                    <div className="d-flex flex-column">
                        <div>
                            <Tag
                                color={paymentStatusColor(record?.status)}
                                style={{
                                    color: '#333',
                                }}
                            >
                                {paymentStatus(record?.status)}
                            </Tag>
                        </div>
                        {!record?.livemode && (
                            <div style={{ marginTop: '5px' }}>
                                <Tag>Modo test</Tag>
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            title: 'Total',
            dataIndex: 'amount',
            render: (text, record) => {
                const formatter = new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: record?.currency?.toUpperCase() ?? 'MXN',
                })
                let amount = text > 0 ? text : record?.amount_due
                return (
                    <Typography.Text
                        style={{
                            color: '#667085',
                            //<span style={{ fontSize: '10px' }}>{record?.currency?.toUpperCase()}</span>
                        }}
                    >
                        {formatter.format(amount / 100)}{' '}
                    </Typography.Text>
                )
            },
        },
        {
            title: 'Fecha',
            dataIndex: 'timestamp',
            render: (text, record) => {
                return (
                    <Typography.Text
                        style={{
                            color: '#667085',
                        }}
                    >
                        {moment(record.timestamp).format('D MMM YY HH:mm')}
                    </Typography.Text>
                )
            },
        },
        {
            title: 'Link de pago',
            dataIndex: 'shortUrl',

            render: (text, record) => {
                let hosted_invoice_url = record?.hosted_invoice_url ? record?.hosted_invoice_url : text
                return (
                    <a href={hosted_invoice_url} target="_blank" rel="noreferrer">
                        <Typography.Text
                            style={{
                                color: '#667085',
                                //limit the size to 100px and one line
                                maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'block',
                            }}
                        >
                            {hosted_invoice_url}
                        </Typography.Text>
                    </a>
                )
            },
        },
        {
            title: 'Facturas relacionadas',
            dataIndex: 'invoices',
            width: '20%',
            render: (text, record) => {
                return record?.invoices?.length > 0 ? (
                    <Typography.Text
                        onClick={() => {}}
                        style={{
                            color: '#667085',
                            //limit the size to 100px and one line
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                        }}
                    >
                        {record?.invoices?.length ?? 0} factura{record?.invoices?.length > 1 ? 's' : ''}
                    </Typography.Text>
                ) : (
                    <></>
                )
            },
        },
    ]

    const data = (pmData?.data ?? [])
        ?.sort((a: any, b: any) => {
            return moment(b?.timestamp).valueOf() - moment(a?.timestamp).valueOf()
        })
        .filter((p: any) => {
            //filter by search in amount, customer name, customer email, customer phone
            let amount = p?.amount > 0 ? p?.amount : p?.amount_due
            let formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: p?.currency?.toUpperCase() ?? 'MXN',
            })
            let amountFormatted = formatter.format(amount / 100)
            let amountFormattedLower = amountFormatted.toLowerCase()
            let searchLower = search.toLowerCase()
            let date = moment(p?.timestamp).format('D MMM YY HH:mm')
            let numberAmount = parseFloat(search)

            return (
                (p?.clientId ?? p?.clientID)?.includes(me?.selectedUser?.id ?? '') &&
                (amountFormattedLower.includes(searchLower) ||
                    date.includes(searchLower) ||
                    (numberAmount > 0 && amount > numberAmount - 10 && amount < numberAmount + 10))
            )
        })

    return (
        <Row>
            <Col xs={24} style={{ borderBottom: '1px solid #D8DAE5', padding: '0px 15px 15px 15px' }}>
                <Row align="middle" justify="space-between">
                    <Typography.Title level={5} style={{ margin: 0 }}>
                        Todos mis pagos
                    </Typography.Title>

                    <Row style={{ width: '30%' }} align="middle">
                        <Form
                            form={form}
                            style={{ flex: 1 }}
                            onFinish={(v) => {
                                setSearch(v.search ?? '')
                            }}
                        >
                            <Form.Item
                                name={'search'}
                                label={null}
                                help={null}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >
                                <Input prefix={<CiSearch />} disabled={false} placeholder={'Buscar ...'} />
                            </Form.Item>
                        </Form>
                        {search !== '' && (
                            <IoCloseOutline
                                style={{ cursor: 'pointer' }}
                                onClick={(_: any) => {
                                    setSearch('')
                                    form.resetFields()
                                }}
                            />
                        )}
                    </Row>
                </Row>
            </Col>
            <Col xs={24}>
                <Table
                    loading={status === 'loading'}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        onChange: (page) => {
                            setCurrentPage(page)
                        },
                        defaultCurrent: currentPage,
                        current: currentPage,
                        pageSize: 10,
                        total: data?.length ?? 0,
                        showSizeChanger: false,
                        hideOnSinglePage: true,
                        position: ['bottomRight'],
                    }}
                    //make the table scrollable
                    scroll={{ x: 500 }}
                    locale={{
                        emptyText: (
                            <div className="d-flex flex-column" style={{}}>
                                <Typography.Text style={{ color: '#667085' }}>
                                    No tienes pagos registrados
                                </Typography.Text>
                            </div>
                        ),
                    }}
                    rowKey={(record) => record.id}
                    size="middle"
                />
            </Col>
        </Row>
    )
}

export default PaymentTable
