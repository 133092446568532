import { configureStore } from '@reduxjs/toolkit'
import teamReducer from '../context/teamSlice'
import userReducer from '../context/userSlice'
import dataReducer from '../context/dataSlice'

const store = configureStore({
    reducer: {
        team: teamReducer,
        user: userReducer,
        data: dataReducer,
    },
})

export default store
