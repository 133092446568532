import { Alert, Button, Col, Form, Input, Row, Select, Typography, message, Collapse } from 'antd'
import { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { fetchMe, fetchPendingReceipts, getClavesForRegimenFiscal, updateMe } from './mehelpers'
import { ReadableTeamDef } from '../../context/teamSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Country, countryLabeledListWithCodeAlpha3 } from '../../datasets/countriesdatasets'
import { taxRegimes } from '../../datasets/taxregimes'
import { invoiceUsage } from '../../datasets/usedataset'
import { usePostHog } from 'posthog-js/react'
import { setData } from '../../context/dataSlice'

const MeTable = () => {
    const dispatch = useDispatch()
    const { team }: { team: ReadableTeamDef } = useSelector((state: any) => state.team)
    const { me } = useSelector((state: any) => state.data)
    const userCtx = useSelector((state: any) => state.user)

    const [loaded, setLoaded] = useState(false)

    const posthog = usePostHog()
    const [form] = Form.useForm()
    const [formErrors, setFormErrors] = useState('')
    const [filterInvoiceUsages, setFilterInvoiceUsages] = useState(invoiceUsage)
    const [retryReceiptsSuccess, setRetryReceiptsSuccess] = useState(false)

    const [failedReceiptsOnRetry, setFailedReceiptsOnRetry] = useState<any[]>([])

    const { status: meStatus } = useQuery('me', () => fetchMe({ teamId: team?.id }), {
        onSuccess: () => {
            setLoaded(true)
        },
        enabled: !loaded,
        retry: true,
        retryDelay: 1000000,
        refetchInterval: 1000000,
        refetchOnWindowFocus: false,
    })

    const retryReceiptsInvoicing = async (pendingReceipts: any[], clientInfo: Record<string, unknown>) => {
        let failedReceiptsCount = 0
        const endpoint = 'receiptsApp/v1/cp/publicinvoice'
        const url = `${process.env.REACT_APP_INTERNAL_API_URL}/${endpoint}`
        const token = localStorage.getItem('authToken')
        const receiptsErrorsMessages = []

        try {
            for (const receipt of pendingReceipts) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: receipt.id,
                        legal_name: clientInfo.legalName,
                        rfc: clientInfo.rfc,
                        tax_system: clientInfo.tax_system,
                        use: clientInfo.use,
                        address: {
                            country: clientInfo.country,
                            zip: clientInfo.zip,
                        },
                    }),
                }
                try {
                    const res = await fetch(url, requestOptions)
                    if (res.status !== 200) {
                        const json = await res.json()
                        throw json
                    }
                } catch (error: any) {
                    failedReceiptsCount++
                    receiptsErrorsMessages.push({ receiptId: receipt.id, message: error.message, url: receipt.url })
                }
            }
        } catch (error) {
            console.log('Error fetching pending receipts')
        }

        if (failedReceiptsCount > 0) {
            setFailedReceiptsOnRetry(receiptsErrorsMessages)
        }

        if (failedReceiptsCount === 0) {
            setRetryReceiptsSuccess(true)
        }
    }

    const postUpdate = async (values: any) => {
        dispatch(
            setData({
                item: 'me',
                data: {
                    ...me,
                    selectedUser: {
                        ...me?.selectedUser,
                        ...values,
                        id: me?.selectedUser?.id || me?.id,
                    },
                },
            }),
        )

        try {
            const updateFetch = await updateMe({ ...values, only: me?.selectedUser ?? null }, team.id)
            return { values: values, data: updateFetch }
        } catch (error) {
            throw error
        }
    }

    const { mutate, isLoading } = useMutation(postUpdate, {
        onSuccess: async (data) => {
            setFormErrors('')

            try {
                posthog.capture('me_updated', {
                    team: team?.id,
                    alias: team?.brand?.alias,
                    user: userCtx?.user?.id,
                })
            } catch (error) {}

            message.success('Tu información ha sido actualizada')

            try {
                const fetchReceipts = await fetchPendingReceipts({ teamId: team?.id })
                const pedingReceipts = fetchReceipts?.data

                if (pedingReceipts?.length > 0) {
                    await retryReceiptsInvoicing(fetchReceipts.data, data.values)
                }
            } catch (error) {
                console.log('Error fetching pending receipts')
            }
        },
        onError: (error: any) => {
            message.error('Hubo un error al actualizar tu información')
            console.log(error)
            setFormErrors(error?.message)

            try {
                posthog.capture('me_update_error', {
                    team: team?.id,
                    alias: team?.brand?.alias,
                    user: userCtx?.user?.id,
                })
            } catch (captureError) {
                console.error('Error capturing update error event:', captureError)
            }
        },
    })

    useEffect(() => {
        if (!me?.selectedUser) {
            form.setFieldsValue({
                ...me,
                ...(me?.address ?? {}),
                tax_system: me?.tax_system ?? '616',
                taxSystem: me?.tax_system ?? '616',
            })
            return
        }

        form.setFieldsValue({
            ...me?.selectedUser,
            ...(me?.selectedUser?.address ?? {}),
            tax_system: me?.selectedUser?.tax_system ?? '616',
            taxSystem: me?.selectedUser?.tax_system ?? '616',
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me?.selectedUser?.id])

    return (
        <Row justify="center">
            <Col xs={24} style={{ borderBottom: '1px solid #D8DAE5', padding: '0px 15px 15px 15px' }}>
                <Row align="middle" justify="space-between">
                    <Typography.Title level={5} style={{ margin: 0 }}>
                        Mi información
                    </Typography.Title>
                </Row>
            </Col>
            <Col xs={24}>
                {meStatus === 'success' ? (
                    <Form
                        onFinish={(v) => mutate(v)}
                        form={form}
                        initialValues={{}}
                        layout="vertical"
                        style={{ padding: '15px' }}
                    >
                        <Row
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                            }}
                        >
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Nombre Fiscal"
                                    tooltip="Añade tu nombre legal SIN acentos y régimen societario en caso de ser Persona Moral"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresa tu nombre fiscal',
                                        },
                                    ]}
                                    name="legalName"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Correo electrónico"
                                    name="email"
                                    tooltip="Para editar este campo, ponte en contacto con soporte"
                                >
                                    <Input readOnly />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="País"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecciona un país',
                                        },
                                    ]}
                                    name="country"
                                >
                                    <Select
                                        placeholder="Selecciona un país"
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={countryLabeledListWithCodeAlpha3.map((country: Country) => {
                                            return {
                                                label: country.name,
                                                value: country.code,
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Código Postal"
                                    name="zip"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresa tu código postal',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="RFC / Identificador Tributario"
                                    name="rfc"
                                    tooltip={
                                        form.getFieldValue('country') === 'MEX'
                                            ? 'RFC con homoclave'
                                            : `Identificador tributario de ${form.getFieldValue('country')}`
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresa tu RFC o identificador tributario',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Régimen Fiscal"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecciona tu regimen societario',
                                        },
                                    ]}
                                    name="tax_system"
                                >
                                    <Select
                                        id="tax_system_select"
                                        placeholder="Selecciona un régimen"
                                        showSearch
                                        onChange={(value) => {
                                            const allowUses = getClavesForRegimenFiscal(value)
                                            const filterInvoiceUsages = invoiceUsage.filter((e) =>
                                                allowUses.includes(e.value),
                                            )

                                            setFilterInvoiceUsages(filterInvoiceUsages)
                                            form.setFieldsValue({ use: filterInvoiceUsages[0].value })
                                        }}
                                        // disabled={form.getFieldValue('country') !== 'MEX'}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={taxRegimes.map((e) => {
                                            return { value: e.value, label: `${e.label} (${e.value})` }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Uso de tus facturas"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecciona el uso de tus facturas',
                                        },
                                    ]}
                                    name="use"
                                >
                                    <Select
                                        id="use_select"
                                        placeholder="Selecciona el uso de tus facturas"
                                        showSearch
                                        // disablsed={form.getFieldValue('country') !== 'MEX'}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={filterInvoiceUsages.map((e) => {
                                            return { value: e.value, label: `${e.label} (${e.value})` }
                                        })}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} style={{ padding: '15px' }}>
                                <Alert
                                    type="info"
                                    closable
                                    message={
                                        <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px' }}>
                                            Consulta tu constancia de situación fiscal para recibir facturas
                                            automáticamente
                                        </Typography.Text>
                                    }
                                    description={
                                        <Typography.Text style={{ fontWeight: '', fontSize: '11px' }}>
                                            Puedes consultar tu información fiscal en{' '}
                                            <a
                                                href="https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal
                                            </a>
                                        </Typography.Text>
                                    }
                                />
                            </Col>

                            {formErrors && (
                                <Col xs={24} style={{ padding: '15px' }}>
                                    <Alert
                                        type="error"
                                        closable
                                        message={
                                            <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px' }}>
                                                Error al actualizar tu información
                                            </Typography.Text>
                                        }
                                        description={
                                            <Typography.Text style={{ fontWeight: '', fontSize: '11px' }}>
                                                {formErrors}
                                            </Typography.Text>
                                        }
                                    />
                                </Col>
                            )}

                            {retryReceiptsSuccess && (
                                <Col xs={24} style={{ padding: '15px' }}>
                                    <Alert
                                        type="success"
                                        closable
                                        message={
                                            <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px' }}>
                                                Las comprar pendientes han sido facturadas
                                            </Typography.Text>
                                        }
                                        description={
                                            <Typography.Text style={{ fontWeight: '', fontSize: '11px' }}>
                                                Las compras que estaban pendientes de facturación han sido facturadas
                                            </Typography.Text>
                                        }
                                    />
                                </Col>
                            )}

                            {failedReceiptsOnRetry.length > 0 && (
                                <Col xs={24} style={{ padding: '15px' }}>
                                    <Alert
                                        type="error"
                                        closable
                                        message={
                                            <Typography.Text style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                Error al reintentar facturación
                                            </Typography.Text>
                                        }
                                        description={
                                            <>
                                                <Typography style={{ fontWeight: '', fontSize: '14px' }}>
                                                    Fallaron {failedReceiptsOnRetry.length} facturas al reintentar la
                                                    facturación
                                                </Typography>

                                                <Collapse
                                                    bordered={false}
                                                    style={{ marginTop: '10px', backgroundColor: 'transparent' }}
                                                    size="small"
                                                    items={[
                                                        {
                                                            key: 'retryReceiptsFailed',
                                                            label: 'Detalles',
                                                            children: (
                                                                <Typography>
                                                                    {failedReceiptsOnRetry.map((e) => (
                                                                        <Typography key={e.receiptId}>
                                                                            <a
                                                                                href={e.url}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {e.receiptId}: {e.message}
                                                                            </a>
                                                                        </Typography>
                                                                    ))}
                                                                </Typography>
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            </>
                                        }
                                    />
                                </Col>
                            )}

                            <Col xs={24}>
                                <Row justify="center">
                                    <Button loading={isLoading} type="primary" htmlType="submit">
                                        Actualizar mi información
                                    </Button>
                                </Row>
                            </Col>
                            <Col xs={24} style={{ marginTop: '25px' }}>
                                <Row justify="center">
                                    <Typography.Text type="secondary" style={{ fontSize: '11px' }}>
                                        {team?.brand?.alias} está automatizando la facturación con gigstack.pro, para
                                        consultar el aviso de privacidad por favor visita{' '}
                                        <a
                                            href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/privacy.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            nuestro aviso de privacidad
                                        </a>
                                    </Typography.Text>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <></>
                )}
            </Col>
        </Row>
    )
}

export default MeTable
