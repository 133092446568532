import { saveAs } from 'file-saver'
// import { useSelector } from "react-redux";
// import { ReadableTeamDef } from "../context/teamSlice";

interface APIRequestDef {
    body: any
    endPoint: string
    extra: any
    type: 'POST' | 'GET' | 'PUT' | 'DELETE'
    test?: boolean
    teamId?: string
    ignoreH?: boolean
}

export const SignedInternalAPIRequest = async ({
    body = {},
    endPoint = '',
    extra,
    type = 'POST',
    test = false,
    teamId = '',
    ignoreH = false,
}: APIRequestDef) => {
    const getInfo = async () => {
        var token = localStorage.getItem('authToken')

        if (!token) {
            var data = localStorage.getItem(`gigCusPortData-${teamId ?? ''}`) ?? ''
            token = data ? JSON.parse(data)?.token : null
        }

        const controller = new AbortController()
        const timeout = 30000

        const timeoutId = setTimeout(() => controller.abort(), timeout)

        let headers: any = {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
            accept: 'application/json',
            //no-cors
            // "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        }
        if (ignoreH) {
            delete headers.Authorization
        }

        var options: any = {
            method: type,
            json: true,
            headers,
            body: JSON.stringify(body),
            timeout,
            signal: controller.signal,
        }

        if (type === 'GET') {
            options = {
                method: 'GET',
                json: true,
                headers,
                timeout,
                signal: controller.signal,
            }
        }

        clearTimeout(timeoutId)

        try {
            const request = new Request(`${process.env.REACT_APP_INTERNAL_API_URL}/${endPoint}`, options)
            // const request = new Request(`http://127.0.0.1:5001/gigstackpro/us-central1/${endPoint}`, options)

            const response = await fetch(request)

            var res

            if (response.status >= 200 && response.status < 400) {
                if (extra?.isFile) {
                    try {
                        //DOWNLOAD BUFFER FROM RESPONSE
                        var blob = await response.blob()
                        saveAs(blob, extra.fileName)

                        return blob
                    } catch (error) {}
                } else {
                    try {
                        const json = await response.json()

                        res = json
                        return res
                    } catch (error) {
                        res = response.body
                        throw res
                    }
                }
                return res
            } else {
                try {
                    const json = await response.json()
                    res = json
                    throw res
                } catch (error) {
                    res = error

                    throw res
                }
            }
        } catch (error) {
            throw error
        }
    }
    return getInfo()
}
