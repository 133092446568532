export const jwtIsValid = async () => {
    const endpoint = 'customerportalapp/v1/auth/pingme'
    const url = `${process.env.REACT_APP_INTERNAL_API_URL}/${endpoint}`
    const token = window.localStorage.getItem('authToken')

    if (!token) return false

    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
        const res = await fetch(url, requestOptions)
        return res.ok
    } catch (error) {
        console.error('Error validating JWT:', error)
        return false
    }
}
