import { Row, Typography } from 'antd'
import React from 'react'
import { AiOutlineIdcard } from 'react-icons/ai'
// import { IoDocumentTextOutline } from 'react-icons/io5'

const ClientColumn = ({ text, record }: { text: any; record: any }) => {
    return (
        <div className="d-flex flex-column" style={{ paddingLeft: '5px' }}>
            <Typography.Text
                style={{
                    fontSize: '13px',
                }}
                strong
            >
                {record?.customer?.legal_name ?? record?.client?.name}
            </Typography.Text>
            <Row align="middle">
                <AiOutlineIdcard style={{ fontSize: '11px', color: '#cecece' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">
                    {record?.customer?.tax_id ?? record?.client?.rfc ?? '-'}
                </Typography.Text>
            </Row>
            <Row align="middle">
                {/* <AiOutlineIdcard style={{ fontSize: '11px', color: '#cecece' }} /> */}
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">
                    {record?.clientId ?? '-'}
                </Typography.Text>
            </Row>
            {/* <Row align="middle">
                <IoDocumentTextOutline style={{ fontSize: '11px', color: '#cecece' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary" copyable>{record?.uuid ?? record?.id}</Typography.Text>
            </Row> */}
        </div>
    )
}

export default ClientColumn
