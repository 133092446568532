export const paymentStatus = (status: string) => {
    switch (status) {
        case 'succeeded':
            return 'Pagado'
        case 'pending':
            return 'Pendiente'
        case 'processing':
            return 'Pendiente'
        case 'requires_payment_method':
            return 'Pendiente'
        case 'requires_confirmation':
            return 'Pendiente'
        case 'canceled':
            return 'Cancelado'
        case 'incomplete':
            return 'Pendiente'
        case 'incomplete_expired':
            return 'Pendiente'
        case 'failed':
            return 'Fallido'
        case 'open':
            return 'Pendiente'

        default:
            return status
    }
}

export const paymentStatusColor = (status: string) => {
    switch (status) {
        case 'succeeded':
            return '#EEF8F4'
        case 'pending':
            return '#DCE0FF'
        case 'processing':
            return '#DCE0FF'
        case 'requires_payment_method':
            return '#DCE0FF'
        case 'requires_confirmation':
            return '#DCE0FF'
        case 'canceled':
            return '#F9DADA'
        case 'incomplete':
            return '#DCE0FF'
        case 'incomplete_expired':
            return '#F9DADA'
        case 'failed':
            return '#F9DADA'
        case 'open':
            return '#DCE0FF'

        default:
            return 'gray'
    }
}

export const invoiceStatus = (status: string) => {
    switch (status) {
        case 'valid':
            return 'Válida'
        case 'canceled':
            return 'Cancelada'

        default:
            return status
    }
}

export const invoiceStatusColor = (status: string) => {
    switch (status) {
        case 'valid':
            return '#EEF8F4'
        case 'canceled':
            return '#F9DADA'

        default:
            return 'gray'
    }
}

export const receiptStatus = (status: string) => {
    switch (status) {
        case 'completed':
            return 'Completo'
        case 'pending':
            return 'Pendiente'

        default:
            return status
    }
}

export const receiptStatusColor = (status: string) => {
    switch (status) {
        case 'completed':
            return '#EEF8F4'
        case 'pending':
            return '#DCE0FF'

        default:
            return 'gray'
    }
}
