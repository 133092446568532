import { useDispatch, useSelector } from 'react-redux'
import { ReadableTeamDef } from '../context/teamSlice'
import { useEffect, useState } from 'react'
import { setUser } from '../context/userSlice'
import { jwtIsValid } from '../helpers/authHelper'

export const useSigningCheck = () => {
    const dispatch = useDispatch()
    const userCtx = useSelector((state: any) => state.user)
    const [tokenIsValid, setTokenIsValid] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const { team }: { team: ReadableTeamDef } = useSelector((state: any) => state.team)

    useEffect(() => {
        const checkToken = async () => {
            setIsLoading(true)
            const gigData = localStorage.getItem(`gigCusPortData-${team?.id ?? ''}`)
            const tokenNotExists = !userCtx.user.token || userCtx.user.token === ''

            if (!gigData) {
                setTokenIsValid(false)
                setIsLoading(false)
                return
            }

            if (tokenNotExists) {
                const decoded = JSON.parse(gigData)
                dispatch(
                    setUser({
                        name: decoded.name ?? '',
                        token: decoded.token ?? '',
                        email: decoded.email ?? '',
                        customers: decoded.customers ?? [],
                    }),
                )
            }

            const res = await jwtIsValid()
            setTokenIsValid(res)
            setIsLoading(false)
        }

        checkToken()
    }, [dispatch, team, userCtx.user.token])

    if (isLoading) {
        return { isLoading: true }
    }

    if (!tokenIsValid) {
        return {
            user: userCtx.user,
            signedIn: false,
            token: null,
        }
    }

    return {
        user: userCtx.user,
        signedIn: true,
        token: userCtx.user.token,
    }
}
