import { Routes, Route } from 'react-router-dom'

import LoginScreen from './LoginScreen'
import NotFound from '../pages/NotFound'

export const NotSignedRoutes = (props: any) => {
    if (props.notFound) {
        return <NotFound />
    }

    return (
        <Routes>
            <Route path="/:team/*" element={<LoginScreen />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}
