//Create a function with these props, team,props, id, type, and that calls /v1/files/download with the props in the body
// const axios = require('axios');

function base64ToBlob(base64Data: string, contentType: string) {
    const binaryData = atob(base64Data)
    const arrayBuffer = new ArrayBuffer(binaryData.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i)
    }

    return new Blob([arrayBuffer], { type: contentType })
}

function downloadFileWorking(base64Data: string, fileName: string, fileType: string) {
    const contentType = 'application/pdf' // Change to the appropriate content type
    const blob = base64ToBlob(base64Data, contentType)
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = `${fileName}.${fileType}` // Specify the desired filename
    a.style.display = 'none'

    document.body.appendChild(a)

    a.click()

    URL.revokeObjectURL(url)
}
export const downloadFile = async ({
    teamId,
    id,
    type,
    test,
    fileType,
}: {
    teamId: string
    id: string
    type: string
    test: boolean
    fileType: string
}) => {
    try {
        const endPoint = 'customerportalapp/v1/files/download'
        let url = `${process.env.REACT_APP_INTERNAL_API_URL}/${endPoint}`
        var token = localStorage.getItem('authToken')

        if (!token) {
            var data = localStorage.getItem(`gigCusPortData-${teamId ?? ''}`) ?? ''
            token = data ? JSON.parse(data)?.token : null
        }
        const body = { test: false, id, type, fileType: fileType ?? 'pdf' }

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }
        const res = await fetch(url, requestOptions)
        var r = await res.json()
        if (res.status >= 200 && res.status < 300) {
            //r.data is base64 file, download it;

            downloadFileWorking(r.data, id, fileType)

            return r
        } else {
            console.log('ERROR')
            console.log(r)

            throw r
        }
    } catch (error) {
        throw error
    }
}
