import { Col, Row, Typography, Table, Tag, Space, Tooltip, Form, Input } from 'antd'
import { useState } from 'react'
import { ReadableTeamDef } from '../../context/teamSlice'
import { useSelector } from 'react-redux'
import type { ColumnsType } from 'antd/es/table'
import moment from 'moment-timezone'
import { receiptStatus, receiptStatusColor } from '../../helpers/helperfunctions'
import { useQuery } from 'react-query'

import { AiOutlineLink } from 'react-icons/ai'
import { fetchReceipts } from './receiptshelpers'
import ClientColumn from '../general/clientcolumn'
import { BsDownload } from 'react-icons/bs'
import { downloadFile } from '../../helpers/fileshelpers'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'
import { usePostHog } from 'posthog-js/react'

const ReceiptsTable = ({ data, status }: { data: any; status: string }) => {
    const posthog = usePostHog()
    const { team }: { team: ReadableTeamDef } = useSelector((state: any) => state.team)
    const { me } = useSelector((state: any) => state.data)
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [form] = Form.useForm()

    moment.locale('es')

    const { data: recData, status: invStatus } = useQuery(
        'receipts',
        () =>
            fetchReceipts({
                test: false,
                teamId: team?.id,
            }),
        {
            enabled: true,
            refetchOnWindowFocus: false,
        },
    )

    let receipts = recData?.data ?? []

    const columns: ColumnsType<any> = [
        {
            title: 'Información fiscal',
            dataIndex: 'customer',
            fixed: 'left',
            render: (text, record) => {
                return <ClientColumn text={text} record={record} />
            },
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            render: (text, record) => {
                return (
                    <Tag
                        color={receiptStatusColor(record?.status)}
                        style={{
                            color: '#333',
                        }}
                    >
                        {receiptStatus(record?.status)}
                    </Tag>
                )
            },
        },
        {
            title: 'Total',
            dataIndex: 'total',
            render: (text, record) => {
                const formatter = new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: record?.currency?.toUpperCase() ?? 'MXN',
                })
                let amount = record?.total

                return (
                    <Typography.Text
                        style={{
                            color: '#667085',
                            //<span style={{ fontSize: '10px' }}>{record?.currency?.toUpperCase()}</span>
                        }}
                    >
                        {formatter.format(amount)}{' '}
                    </Typography.Text>
                )
            },
        },
        {
            title: 'Fecha',
            dataIndex: 'timestamp',
            render: (text, record) => {
                return (
                    <Typography.Text
                        style={{
                            color: '#667085',
                        }}
                    >
                        {moment(record.timestamp).format('D MMM YY HH:mm')}
                    </Typography.Text>
                )
            },
        },
        {
            title: 'Fecha límite',
            dataIndex: 'validUntil',

            render: (text, record) => {
                return (
                    <Typography.Text
                        style={{
                            color: '#667085',
                            //limit the size to 100px and one line
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                        }}
                    >
                        {moment(record.validUntil).tz('America/Mexico_City').format('D MMM YY HH:mm')}
                    </Typography.Text>
                )
            },
        },
        {
            title: 'Facturas relacionadas',
            dataIndex: 'payments',
            width: '20%',
            render: (text, record) => {
                return record?.invoices?.length > 0 ? (
                    <Typography.Text
                        onClick={() => {}}
                        style={{
                            color: '#667085',
                            //limit the size to 100px and one line
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                        }}
                    >
                        {record?.invoices?.length ?? 0} factura{record?.invoices?.length > 1 ? 's' : ''}
                    </Typography.Text>
                ) : (
                    <></>
                )
            },
        },
        {
            title: '',
            dataIndex: '',
            render: (text, record) => {
                return (
                    <Space style={{ marginRight: '5px' }}>
                        <Tooltip title="Ir a facturar">
                            {record.status === 'pending' && (
                                <AiOutlineLink
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        try {
                                            posthog.capture('autoinvoice', {
                                                team: team?.id,
                                                receipt: record?.id,
                                            })
                                        } catch (error) {}
                                        window.open(`https://beta.gigstack.pro/autofactura?id=${record?.id}`, '_blank')
                                    }}
                                />
                            )}
                        </Tooltip>
                        <BsDownload
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                posthog.capture('receipt_file_downloaded', {
                                    team: team?.id,
                                    receipt: record?.id,
                                })
                                downloadFile({
                                    id: record.id,
                                    teamId: team?.id,
                                    type: 'receipt',
                                    fileType: 'pdf',
                                    test: false,
                                })
                            }}
                        />
                    </Space>
                )
            },
        },
    ]

    const usingData = (receipts ?? [])
        ?.sort((a: any, b: any) => {
            return moment(b?.timestamp).valueOf() - moment(a?.timestamp).valueOf()
        })
        .filter((p: any) => {
            //filter by search in amount, customer name, customer email, customer phone
            let amount = p?.total > 0 ? p?.total : p?.total
            let formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: p?.currency?.toUpperCase() ?? 'MXN',
            })
            let amountFormatted = formatter.format(amount)
            let amountFormattedLower = amountFormatted.toLowerCase()
            let searchLower = search.toLowerCase()
            let date = moment(p?.timestamp).format('D MMM YY HH:mm')
            var numberAmount = Number(search)

            //filter in total if search is 100 above or below

            return (
                (p?.clientId ?? p?.clientID)?.includes(me?.selectedUser?.id ?? '') &&
                (amountFormattedLower.includes(searchLower) ||
                    date.includes(searchLower) ||
                    (numberAmount > 0 && amount > numberAmount - 10 && amount < numberAmount + 10))
            )
        })

    return (
        <Row>
            <Col xs={24} style={{ borderBottom: '1px solid #D8DAE5', padding: '0px 15px 15px 15px' }}>
                <Row align="middle" justify="space-between">
                    <Typography.Title level={5} style={{ margin: 0 }}>
                        Todos mis recibos
                    </Typography.Title>
                    {/* <Row align="middle">
                        <div style={{ border: '1px solid #D8DAE5', borderRadius: '5px', padding: '1px 15px', cursor: 'pointer' }}>
                            <Row align="middle">
                                {loading.includes('downloadAll') ? <Oval
                                    height={10}
                                    width={10}
                                    color={team?.brand?.secondaryColor ?? "white"}
                                    wrapperStyle={{
                                        marginRight: '5px'
                                    }}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor={team?.brand?.secondaryColor ?? "#cecece"}
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                /> : <AiOutlineDownload style={{
                                    color: '#474D66',
                                    marginRight: '5px'
                                }} />}
                                <Typography.Text style={{
                                    color: '#474D66',
                                }}>Descargar mis facturas</Typography.Text>
                            </Row>
                        </div>
                    </Row> */}
                    <Row style={{ width: '30%' }} align="middle">
                        <Form
                            form={form}
                            style={{ flex: 1 }}
                            onFinish={(v) => {
                                setSearch(v.search ?? '')
                            }}
                        >
                            <Form.Item
                                name={'search'}
                                label={null}
                                help={null}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >
                                <Input prefix={<CiSearch />} disabled={false} placeholder={'Buscar ...'} />
                            </Form.Item>
                        </Form>
                        {search !== '' && (
                            <IoCloseOutline
                                style={{ cursor: 'pointer' }}
                                onClick={(_: any) => {
                                    setSearch('')
                                    form.resetFields()
                                }}
                            />
                        )}
                    </Row>
                </Row>
            </Col>
            <Col xs={24}>
                <Table
                    loading={invStatus === 'loading'}
                    columns={columns}
                    dataSource={usingData}
                    pagination={{
                        onChange: (page) => {
                            setCurrentPage(page)
                        },
                        defaultCurrent: currentPage,
                        current: currentPage,
                        pageSize: 10,
                        total: usingData?.length ?? 0,
                        showSizeChanger: false,
                        hideOnSinglePage: true,
                        position: ['bottomRight'],
                    }}
                    locale={{
                        emptyText: (
                            <div className="d-flex flex-column" style={{}}>
                                <Typography.Text style={{ color: '#667085' }}>
                                    No tienes recibos registrados
                                </Typography.Text>
                            </div>
                        ),
                    }}
                    //make the table scrollable
                    scroll={{ x: 500 }}
                    rowKey={(record) => record.id}
                    size="middle"
                />
            </Col>
        </Row>
    )
}

export default ReceiptsTable
