import { Avatar, Button, Col, ConfigProvider, Divider, Layout, Menu, Popover, Row, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReadableTeamDef } from '../context/teamSlice'
import { setUser } from '../context/userSlice'
import { CiWallet, CiReceipt, CiViewList, CiUser } from 'react-icons/ci'
import PaymentTable from '../components/payments/paymentstable'

import { fetchMe } from '../components/me/mehelpers'
import InvoicesTable from '../components/invoices/invoicestable'
import ReceiptsTable from '../components/receipts/receiptstable'
import MeTable from '../components/me/metable'
import { usePostHog } from 'posthog-js/react'
import { CaretDown, CaretLeft, CaretRight, Check, List, Users } from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import posthog from 'posthog-js'
// import { stringsLocale } from '../locale/stringslocale';

function HeaderContent({
    width,
    paddingLeft,
    collapsed,
    changeCollapse,
}: {
    width: string
    paddingLeft: string
    collapsed: boolean
    changeCollapse: Function
}) {
    const dispatch = useDispatch()
    const { me } = useSelector((state: any) => state.data)
    const posthog = usePostHog()
    const [clientsOpen, setClientsOpen] = useState(false)
    const [loading, setLoading] = useState<string[]>(['payments', 'invoices', 'receipts', 'me'])
    const { team }: { team: ReadableTeamDef } = useSelector((state: any) => state.team)
    const { user }: { user: any } = useSelector((state: any) => state.user)
    const { selectedUser } = user
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const getClientInfo = async () => {
            try {
                setLoaded(false)
                if (user?.selectedUser) return
                const data = await fetchMe({ teamId: team?.id })
                setLoaded(true)

                dispatch(setData({ item: 'me', data: { ...data.data, selectedUser: null } }))
            } catch (error) {}
        }
        getClientInfo()

        // eslint-disable-next-line
    }, [])

    const handleLogout = async () => {
        setLoading([...loading, 'logout'])

        try {
            posthog.capture('logout', {
                team: team?.id,
                alias: team?.brand?.alias,
                user: user?.name,
            })
        } catch (error) {}

        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem(`gigCusPortSessionId-${team.id}`)
        localStorage.removeItem(`gigCusPortData-${team?.id ?? ''}`)

        await new Promise((resolve) => setTimeout(resolve, 1000))
        dispatch(setUser({ name: '', token: null, email: null, customers: [] }))
        setLoading(loading.filter((item) => item !== 'logout'))
    }

    const innerWidth = window.innerWidth
    const headerStyle: React.CSSProperties = {
        textAlign: 'center',
        color: '#fff',
        height: 64,
        paddingInline: innerWidth < 780 ? 10 : 50,
        lineHeight: '64px',
        backgroundColor: 'white',
        paddingLeft,
        borderBottom: '1px solid #e8e8e8',
        //FIXED
        position: 'fixed',
        zIndex: 15,
        width,
    }

    const ChipSelector = () => {
        const using = me?.selectedUser ?? me
        return (
            <Popover
                trigger={['click']}
                className="clickable"
                open={clientsOpen}
                onOpenChange={(open) => setClientsOpen(open)}
                content={
                    <div
                        className="d-flex flex-column"
                        style={{
                            marginTop: '5px',
                            WebkitOverflowScrolling: 'touch',
                        }}
                    >
                        <Row
                            key={'todos'}
                            style={{ marginTop: '3px', cursor: 'pointer', padding: '0px 10px 0px 0px' }}
                            className="teamRowSelector hoverRow"
                            align="middle"
                            onClick={async (_: any) => {
                                dispatch(setData({ item: 'me', data: { ...me, selectedUser: null } }))
                            }}
                        >
                            <Row style={{ padding: '5px 10px', width: '100%' }} justify="space-between" align="middle">
                                <Typography.Text
                                    style={{
                                        fontSize: '11px',
                                        maxLines: 1,
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        marginRight: '15px',
                                    }}
                                >
                                    Ver todos ({me?.customers?.length})
                                </Typography.Text>
                                <Users
                                    size={15}
                                    className={me?.selectedUser ? 'icon' : ''}
                                    style={{ color: !me?.selectedUser ? team?.brand?.primaryColor : '' }}
                                />
                            </Row>
                        </Row>

                        <div
                            style={{
                                maxHeight: '420px',
                                overflow: 'auto',
                            }}
                        >
                            {me?.customers?.map((user: any) => {
                                return (
                                    <Row
                                        key={user?.id}
                                        style={{
                                            marginTop: '3px',
                                            cursor: 'pointer',
                                            borderBottom: '1px solid #f7f7f7',
                                            paddingBottom: '5px',
                                            paddingLeft: '10px',
                                            paddingRight: '5px',
                                            borderRadius: '5px',
                                        }}
                                        className="teamRowSelector hoverRow"
                                        align="middle"
                                        onClick={async (_: any) => {
                                            // dispatch(setUser({ ...user, selectedUser: user }))
                                            dispatch(setData({ item: 'me', data: { ...me, selectedUser: user } }))
                                            // console.log(user);
                                        }}
                                    >
                                        <Avatar
                                            size={20}
                                            src={user?.photoURL}
                                            shape="square"
                                            style={{
                                                marginRight: '10px',
                                                fontSize: '12px',
                                                backgroundColor: team?.brand?.primaryColor,
                                            }}
                                        >
                                            {user?.name?.substring(1, 0)}
                                        </Avatar>
                                        <div className="d-flex flex-column" style={{ padding: '5px 10px', flex: 1 }}>
                                            <Row align="middle">
                                                <Typography.Text
                                                    style={{
                                                        fontSize: '11px',
                                                        maxLines: 1,
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {user?.legalName ?? '¿'}
                                                </Typography.Text>
                                            </Row>
                                            <Typography.Text
                                                type="secondary"
                                                style={{ fontSize: '10px', margin: 0, padding: 0 }}
                                            >
                                                {user?.id}
                                            </Typography.Text>
                                        </div>
                                        {user?.id === me?.selectedUser?.id && <Check size={15} className="icon" />}
                                    </Row>
                                )
                            })}
                        </div>
                        <Divider style={{ margin: '5px 0px' }} />

                        <Button loading={loading.includes('logout')} onClick={handleLogout} type="default">
                            Cerrar sesión{' '}
                        </Button>
                    </div>
                }
            >
                <div
                    style={{
                        padding: '5px 10px',
                        borderRadius: '5px',
                        backgroundColor: '#fafbfd',
                        minWidth: '210px',
                        maxWidth: '210px',
                        overflow: 'hidden',
                        minHeight: '40px',
                        maxHeight: '40px',

                        justifyContent: 'center',
                    }}
                    className="d-flex flex-column "
                >
                    <Row align="middle" className="clickable">
                        {me?.customers?.length > 1 && (
                            <CaretDown style={{ padding: 0, marginRight: '5px' }} size={15} className="icon" />
                        )}
                        <Typography.Text
                            ellipsis
                            style={{
                                textAlign: 'left',
                                fontSize: '13px',
                                margin: 0,
                                padding: 0,
                                flex: 1,
                            }}
                        >
                            {using?.legalName ?? using?.name} {selectedUser ? '' : `(${me?.customers?.length})`}
                        </Typography.Text>
                    </Row>
                </div>
            </Popover>
        )
    }

    return (
        <Layout.Header style={headerStyle}>
            <Row justify="space-between" align="middle" style={{ minWidth: '100%', height: '100%' }}>
                {collapsed && window.innerWidth < 780 ? (
                    <div
                        style={{ margin: 0, height: '100%', paddingLeft: '10px' }}
                        className="d-flex flex-column align-items-center justify-content-center"
                    >
                        <Row align="middle">
                            <List
                                size={20}
                                className="icon"
                                style={{ marginRight: '5px' }}
                                onClick={() => {
                                    changeCollapse(!collapsed)
                                    try {
                                        posthog.capture('collapse_top', {
                                            team: team?.id,
                                            alias: team?.brand?.alias,
                                            user: user?.name,
                                        })
                                    } catch (error) {
                                        console.log(error)
                                    }
                                }}
                            />
                            <img
                                src={team?.brand?.logo ?? ''}
                                alt=""
                                style={{ height: '25px', padding: 0, margin: 0 }}
                            />
                        </Row>
                    </div>
                ) : (
                    <div></div>
                )}

                <Row>{!loaded ? <Spin /> : <ChipSelector />}</Row>
            </Row>
        </Layout.Header>
    )
}

function Dashboard() {
    const { team }: { team: ReadableTeamDef } = useSelector((state: any) => state.team)
    const [activeTab, setActiveTab] = useState('payments')
    const [collapsed, setCollapsed] = useState(false)
    const isMobile = window.innerWidth < 780

    useEffect(() => {
        if (isMobile) {
            setCollapsed(true)
        }
    }, [isMobile])

    const ToRender = () => {
        switch (activeTab) {
            case 'payments':
                return <PaymentTable />

            case 'invoices':
                return <InvoicesTable status={''} data={[]} />
            case 'receips':
                return <ReceiptsTable status="" data={[]} />
            case 'me':
                return <MeTable />
            default:
                return <PaymentTable />
        }
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: team?.brand?.primaryColor ?? '#00b96b',
                    colorLink: team?.brand?.primaryColor ?? '#00b96b',
                    colorText: '#333',
                },
                components: {},
            }}
        >
            <Layout>
                <Layout>
                    <HeaderContent
                        width={collapsed ? '100%' : '100%'}
                        paddingLeft={collapsed ? '0px' : '230px'}
                        collapsed={collapsed}
                        changeCollapse={setCollapsed}
                    />
                    <Layout.Sider
                        collapsedWidth={window.innerWidth < 780 ? 0 : 60}
                        defaultCollapsed={window.innerWidth < 780}
                        collapsed={collapsed}
                        onCollapse={(collapsed) => setCollapsed(collapsed)}
                        style={{
                            position: 'fixed',
                            height: '100vh',
                            left: 0,
                            zIndex: 100,
                            overflow: 'hidden',
                            borderRight: '1px solid #D8DAE5',
                            backgroundColor: 'white',
                        }}
                    >
                        <Row style={{ height: '100%' }}>
                            <Col
                                xs={24}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div
                                    style={{ margin: 0, padding: '0px', flex: 1, marginTop: '25px' }}
                                    className="d-flex flex-column "
                                >
                                    <Row justify="center">
                                        <img
                                            src={team?.brand?.logo ?? ''}
                                            alt=""
                                            style={{ height: '30px', padding: 0, margin: 0 }}
                                        />
                                    </Row>
                                    <Menu
                                        onClick={(v) => {
                                            setActiveTab(v.key)
                                            if(isMobile) setCollapsed(true)
                                        }}
                                        defaultSelectedKeys={['payments']}
                                        style={{ backgroundColor: 'white', flex: 1, marginTop: '15px' }}
                                        mode="vertical"
                                        items={[
                                            {
                                                key: 'payments',
                                                icon: <CiWallet />,
                                                label: 'Pagos',
                                            },
                                            {
                                                key: 'invoices',
                                                label: 'Facturas',
                                                icon: <CiReceipt />,
                                            },
                                            {
                                                key: 'receips',
                                                label: 'Recibos de venta',
                                                icon: <CiViewList />,
                                            },
                                            {
                                                key: 'me',
                                                label: 'Perfil',
                                                icon: <CiUser />,
                                            },
                                        ]}
                                    />
                                </div>

                                <div
                                    className="d-flex flex-column"
                                    style={{ padding: '10px 10px', backgroundColor: 'white' }}
                                >
                                    {collapsed && window.innerWidth > 780 && (
                                        <Row justify="center">
                                            <a
                                                href={`https://gigstack.pro?utm_source=customerportal-${team?.id}&utm_medium=poweredby&utm_campaign=customerportal-${team?.id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ margin: '0px', padding: '0px' }}
                                            >
                                                <img
                                                    src={
                                                        collapsed
                                                            ? 'https://pro-gigstack.s3.us-east-2.amazonaws.com/logos/gigstack_logo_icon.jpeg'
                                                            : 'https://pro-gigstack.s3.us-east-2.amazonaws.com/logos/Powered+by.png'
                                                    }
                                                    height="30px"
                                                    alt="gistack.pro"
                                                />
                                            </a>
                                        </Row>
                                    )}
                                    <Row justify={collapsed ? 'center' : 'space-between'} align="middle" wrap>
                                        {collapsed ? (
                                            <CaretRight
                                                size={20}
                                                className="icon clickable"
                                                onClick={() => setCollapsed(!collapsed)}
                                            />
                                        ) : (
                                            <CaretLeft
                                                size={20}
                                                className="icon clickable"
                                                onClick={() => setCollapsed(!collapsed)}
                                            />
                                        )}
                                        {!collapsed && (
                                            <a
                                                href={`https://gigstack.pro?utm_source=customerportal-${team?.id}&utm_medium=poweredby&utm_campaign=customerportal-${team?.id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ margin: '0px', padding: '0px' }}
                                            >
                                                <img
                                                    src={
                                                        collapsed
                                                            ? 'https://pro-gigstack.s3.us-east-2.amazonaws.com/logos/gigstack_logo_icon.jpeg'
                                                            : 'https://pro-gigstack.s3.us-east-2.amazonaws.com/logos/Powered+by.png'
                                                    }
                                                    height="30px"
                                                    alt="gistack.pro"
                                                />
                                            </a>
                                        )}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Layout.Sider>
                    <Layout.Content
                        style={{
                            backgroundColor: 'white',
                            padding: isMobile ? '0px' : '20px',
                            minHeight: '93vh',
                            transition: 'margin-left 0.3s',
                            marginLeft:
                                collapsed && window.innerWidth > 780
                                    ? '60px'
                                    : collapsed && window.innerWidth < 780
                                      ? '0px'
                                      : collapsed
                                        ? '60px'
                                        : '200px',

                            marginTop: '64px',
                        }}
                    >
                        <Row justify="center">
                            {/* <HeaderClient /> */}
                            <Col xs={23} style={{ marginTop: '0px' }}>
                                <Layout style={{ backgroundColor: 'white' }}>
                                    <Layout.Content
                                        style={{
                                            backgroundColor: 'white',
                                            minHeight: '10px',
                                            borderRadius: '15px',
                                            borderBottomRightRadius: '15px',
                                            overflow: 'hidden',
                                            padding: isMobile ? '0px' : '20px',
                                        }}
                                    >
                                        <Row>
                                            <Col xs={24} style={{}}></Col>
                                        </Row>

                                        <Row style={{ marginTop: '20px' }}>
                                            <Col
                                                xs={24}
                                                style={{
                                                    border: '1px solid #D8DAE5',
                                                    padding: '25px 0px',
                                                    borderRadius: '10px',
                                                }}
                                            >
                                                <ToRender />
                                            </Col>
                                        </Row>
                                    </Layout.Content>
                                </Layout>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col xs={23} style={{}}>
                                <Row justify="center" align="middle">
                                    <img src={team?.brand?.logo ?? ''} height="30px" alt={team?.brand?.alias} />
                                    <Divider type="vertical" />
                                    <a
                                        href={`https://gigstack.pro?utm_source=customerportal-${team?.id}&utm_medium=poweredby&utm_campaign=customerportal-${team?.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={'https://pro-gigstack.s3.us-east-2.amazonaws.com/logos/Powered+by.png'}
                                            height="50px"
                                            alt="gistack.pro"
                                        />
                                    </a>
                                </Row>
                            </Col>
                            {/* <Col xs={24} style={{ marginTop: '10px' }}>
                                <a style={{ fontSize: '11px', color: "#333" }} target="_blank" rel="noreferrer" href='https://forms.gle/YUnEwF9YDYj2Qrdc9'>¿Nos ayudas a mejorar?</a>
                            </Col> */}
                        </Row>
                    </Layout.Content>
                    {collapsed && window.innerWidth < 780 && (
                        <div 
                            style={{
                                position: "fixed",
                                bottom: "10px",
                                left: "10px",
                                backgroundColor: "#f0f0f0",
                                padding: "10px",
                                borderRadius: "5px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                zIndex: 1000
                            }}
                        >
                            <CaretRight 
                                size={20}
                                className="icon clickable"
                                onClick={() => {
                                    setCollapsed(!collapsed)
                                    try {
                                        posthog.capture('collapse_bottom', {
                                            team: team?.id,
                                            alias: team?.brand?.alias
                                        })
                                    } catch (error) {
                                        console.log(error)
                                    }
                                }}
                            />
                        </div>
                    )}
                </Layout>
            </Layout>
        </ConfigProvider>
    )
}

export default Dashboard
