import { createSlice } from '@reduxjs/toolkit'

export interface ReadableTeamDef {
    brand: {
        logo: string
        alias: string
        primaryColor: string
        secondaryColor?: string
    } | null
    id: string
    supportEmail: string | null | undefined
    supportPhone?: string | null | undefined
}

const readableTeamDefSet = (object: any) => {
    const team: ReadableTeamDef = {
        brand: object['brand']
            ? {
                  logo: object['brand']['logo'] ?? '',
                  alias: object['brand']['alias'] ?? '',
                  primaryColor: object['brand']['primaryColor'] ?? '',
                  secondaryColor: object['brand']['secondaryColor'] ?? '',
              }
            : null,
        id: object['id'] ?? '',
        supportEmail: object['supportEmail'] ?? '',
        supportPhone: object['supportPhone'] ?? '',
    }

    return team
}

interface TeamState {
    team: ReadableTeamDef
}

const initialState: TeamState = {
    team: readableTeamDefSet({}),
}

export const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeam: (state, action) => {
            state.team = action.payload
        },
    },
})

export const { setTeam } = teamSlice.actions

export default teamSlice.reducer
