export interface TaxRegime {
    value: string
    label: string
}

export const taxRegimes: TaxRegime[] = [
    { value: '601', label: 'Ley General de Personas Morales' },
    { value: '603', label: 'Personas Morales con Fines no Lucrativos' },
    { value: '605', label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { value: '606', label: 'Arrendamiento' },
    { value: '607', label: 'Régimen de Enajenación o Adquisición de Bienes' },
    { value: '608', label: 'Demás ingresos' },
    { value: '609', label: 'Consolidación' },
    { value: '610', label: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
    // { value: "610", label: "Residentes en el Extranjero" },
    { value: '611', label: 'Ingresos por Dividendos(socios y accionistas)' },
    { value: '612', label: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { value: '614', label: 'Ingresos por intereses' },
    { value: '615', label: 'Régimen de los ingresos por obtención de premios' },
    { value: '616', label: 'Sin obligaciones fiscales' },
    { value: '620', label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { value: '621', label: 'Régimen de Incorporación Fiscal' },
    { value: '622', label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { value: '623', label: 'Opcional para Grupos de Sociedades' },
    { value: '624', label: 'Coordinados' },
    {
        value: '625',
        label: 'Régimen de las Actividades Empresariales con ingresos a través de plataformas tecnológicas',
    },
    { value: '626', label: 'Régimen simplificado de confianza' },
    //{ value: '628', label: 'Hidrocarburos' },
    { value: '629', label: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
    { value: '630', label: 'Enajenación de acciones en bolsa de valores' },
]
