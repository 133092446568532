import { FunctionComponent, useEffect, useState } from 'react'

import './App.css'
import { Route, Routes } from 'react-router-dom'
import { useSigningCheck } from './app/customhooks/authHook'
import AuthScreensWrapper from './app/auth/AuthScreensWrapper'
import { NotSignedRoutes } from './app/auth/NotSignedRoutes'
import { SignedInternalAPIRequest } from './app/functions/apiRequest'
import { setTeam } from './app/context/teamSlice'
import { useDispatch, useSelector } from 'react-redux'
import Dashboard from './app/pages/Dashboard'
import NotFound from './app/pages/NotFound'
import moment from 'moment-timezone'
import 'moment/locale/es'
import { QueryClient, QueryClientProvider } from 'react-query'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

type AuthWrapperProps = {
    children: JSX.Element
    fallback: JSX.Element
}

export const AuthWrapper: FunctionComponent<AuthWrapperProps> = ({ children, fallback }: AuthWrapperProps) => {
    const authUser = useSigningCheck()

    if (!children) {
        throw new Error('Children must be provided')
    }
    if (authUser?.signedIn === true) {
        return children
    } else {
        return fallback
    }
}

function App() {
    moment.locale('es')

    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)

    const [notFound, setNotFound] = useState(false)

    const teamId = window.location.pathname.split('/')[1]

    useEffect(() => {
        posthog.init('phc_ud0yyxYqNQb05H9wNaTFcFcLPSJfLHzun1E9jIvV74f', { api_host: 'https://app.posthog.com' })
    }, [])

    useEffect(() => {
        const getTeam = async () => {
            if (teamId === team?.id) return
            try {
                const data = await SignedInternalAPIRequest({
                    body: {
                        id: teamId,
                    },
                    endPoint: `customerportalapp/v1/context/team`,
                    extra: {},
                    type: 'POST',
                    test: false,
                    teamId: team?.id ?? teamId,
                    ignoreH: true,
                })
                try {
                    posthog.capture('team_retrieved', {
                        team: data?.id,
                        alias: data?.alias,
                    })
                } catch (error) {
                    console.log(`Error building posthog event`, error)
                }

                dispatch(setTeam(data))
            } catch (error: any) {
                if (error?.message === 'ID not found') {
                    setNotFound(true)
                }
                console.log(`Error getting team`, error)
            }
        }
        getTeam()

        //eslint-disable-next-line
    }, [])

    const queryClient = new QueryClient()
    return (
        <PostHogProvider client={posthog}>
            <AuthWrapper fallback={<NotSignedRoutes notFound={notFound} />}>
                <QueryClientProvider client={queryClient}>
                    <AuthScreensWrapper>
                        <Routes>
                            <Route path="/:team/*" element={<Dashboard />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </AuthScreensWrapper>
                </QueryClientProvider>
            </AuthWrapper>
        </PostHogProvider>
    )
}

export default App
