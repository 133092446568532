import { createSlice } from '@reduxjs/toolkit'

export interface ReadableUserDef {
    name: string | null
    token: string | null
    email?: string | null
    customers?: any[] | null
    selectedUser?: any | null
}

const readableUserDefSet = (object: any) => {
    const user: ReadableUserDef = {
        name: object['name'] ?? '',
        token: object['token'] ?? '',
        email: object['email'] ?? '',
        customers: object['customers'] ?? [],
        selectedUser: object['selectedUser'] ?? {},
    }

    return user
}

interface UserState {
    user: ReadableUserDef
}

const initialState: UserState = {
    user: readableUserDefSet({}),
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
