import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './app/config/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <ConfigProvider
        theme={{
            token: { colorPrimary: '#00b96b' },
            components: {
                Input: {
                    controlHeight: 33,
                    colorBgContainer: '#fafbfd',
                    colorTextLabel: '#474D66',
                },
                Select: {
                    colorTextLabel: '#474D66',
                    controlHeight: 33,
                    colorBgContainer: '#fafbfd',
                },
            },
        }}
    >
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ConfigProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
