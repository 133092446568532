import { Col, Layout, Row, Typography } from 'antd'

function NotFound() {
    return (
        <Layout style={{ padding: '0px 25px' }}>
            <Layout.Content style={{ minHeight: '100vh' }}>
                <Row justify="center" style={{ height: '100vh' }} align="middle">
                    <Col xs={24} md={12} style={{ padding: '0px 25px', height: '100%' }}>
                        <div
                            className="d-flex flex-column"
                            style={{
                                padding: '0px 25px',
                                height: '100%',
                                justifyContent: 'center',
                                alignContent: 'center',
                            }}
                        >
                            <Typography.Title level={3} style={{ textAlign: 'center' }}>
                                No hemos encontrado el portal solicitado
                            </Typography.Title>
                            <Typography.Text type="secondary" style={{ textAlign: 'center' }}>
                                Por favor, verifique la URL que ha ingresado e intente nuevamente
                            </Typography.Text>
                        </div>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    )
}

export default NotFound
