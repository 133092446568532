import { Col, Row, Typography, Table, Tag, Space, message, Dropdown, MenuProps, Form, Input } from 'antd'
import { useEffect, useState } from 'react'
import { ReadableTeamDef } from '../../context/teamSlice'
import { useSelector } from 'react-redux'
import type { ColumnsType } from 'antd/es/table'
import moment from 'moment-timezone'
import { invoiceStatus, invoiceStatusColor } from '../../helpers/helperfunctions'
import { useQuery } from 'react-query'

import { fetchInvoices } from './invoiceshelpers'

import ClientColumn from '../general/clientcolumn'
import { BsDownload, BsEye } from 'react-icons/bs'
import { downloadFile } from '../../helpers/fileshelpers'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'
import { usePostHog } from 'posthog-js/react'

const InvoicesTable = ({ data, status }: { data: any; status: string }) => {
    const posthog = usePostHog()
    const { team }: { team: ReadableTeamDef } = useSelector((state: any) => state.team)
    const { me } = useSelector((state: any) => state.data)
    // const { user: userCtx } = useSelector((state: any) => state.user)

    // const [loading, setLoading] = useState<string[]>([])
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    // const [usingData, setUsingData] = useState<any[]>([])

    const [form] = Form.useForm()

    moment.locale('es')
    //set moment to es_MX
    const { data: invData, status: invStatus } = useQuery(
        'invoices',
        () =>
            fetchInvoices({
                test: false,
                teamId: team?.id,
            }),
        {
            enabled: true,
            refetchOnWindowFocus: true,
        },
    )

    const [usingData, setUsingData] = useState([])

    useEffect(() => {
        const invs = (invData?.data ?? [])
            .sort((a: any, b: any) => {
                return moment(b?.timestamp).valueOf() - moment(a?.timestamp).valueOf()
            })
            .filter((p: any) => {
                //filter by search in amount, customer name, customer email, customer phone
                let amount = p?.total > 0 ? p?.total : p?.total
                let formatter = new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: p?.currency?.toUpperCase() ?? 'MXN',
                })
                let amountFormatted = formatter.format(amount)
                let amountFormattedLower = amountFormatted.toLowerCase()
                let searchLower = search.toLowerCase()
                let date = moment(p?.timestamp).format('D MMM YY HH:mm')
                var numberAmount = Number(search)

                return (
                    (p?.clientId ?? p?.clientID)?.includes(me?.selectedUser?.id ?? '') &&
                    (amountFormattedLower.includes(searchLower) ||
                        date.includes(searchLower) ||
                        (numberAmount > 0 && amount > numberAmount - 10 && amount < numberAmount + 10))
                ) /* ||
                date.includes(searchLower) ||
                (numberAmount > 0 && amount > numberAmount - 100 && amount < numberAmount + 100) || */
            })
        setUsingData(() => invs)
        // eslint-disable-next-line
    }, [me?.selectedUser?.id, search, invStatus])

    const items: MenuProps['items'] = [
        {
            label: 'PDF',
            key: 'pdf',
        },
        {
            label: 'XML',
            key: 'xml',
        },
    ]

    const columns: ColumnsType<any> = [
        {
            title: 'Información fiscal',
            dataIndex: 'customer',
            fixed: 'left',
            render: (text, record) => {
                return <ClientColumn text={text} record={record} />
            },
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            render: (text, record) => {
                return (
                    <Row>
                        <Tag
                            color={invoiceStatusColor(record?.status)}
                            style={{
                                color: '#333',
                            }}
                        >
                            {invoiceStatus(record?.status)}
                        </Tag>
                        {!record?.livemode && <Tag>Modo test</Tag>}
                    </Row>
                )
            },
        },
        {
            title: 'Total',
            dataIndex: 'total',
            render: (text, record) => {
                const formatter = new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: record?.currency?.toUpperCase() ?? 'MXN',
                })
                let amount = record?.total

                return (
                    <Typography.Text
                        style={{
                            color: '#667085',
                            //<span style={{ fontSize: '10px' }}>{record?.currency?.toUpperCase()}</span>
                        }}
                    >
                        {formatter.format(amount)}{' '}
                    </Typography.Text>
                )
            },
        },
        {
            title: 'Fecha',
            dataIndex: 'timestamp',
            render: (text, record) => {
                return (
                    <Typography.Text
                        style={{
                            color: '#667085',
                        }}
                    >
                        {moment(record.timestamp).format('D MMM YY HH:mm')}
                    </Typography.Text>
                )
            },
        },
        {
            title: 'Serie y Folio',
            dataIndex: 'folio_number',

            render: (text, record) => {
                return (
                    <Typography.Text
                        style={{
                            color: '#667085',
                            //limit the size to 100px and one line
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                        }}
                    >
                        {record.series} {record.folio_number}
                    </Typography.Text>
                )
            },
        },
        {
            title: 'Pagos relacionadas',
            dataIndex: 'payments',
            width: '20%',
            render: (text, record) => {
                return record?.payments?.length > 0 ? (
                    <Typography.Text
                        onClick={() => {}}
                        style={{
                            color: '#667085',
                            //limit the size to 100px and one line
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                        }}
                    >
                        {record?.payments?.length ?? 0} pago{record?.payments?.length > 1 ? 's' : ''}
                    </Typography.Text>
                ) : (
                    <></>
                )
            },
        },
        {
            title: '',
            dataIndex: 'actions',
            width: '10%',
            render: (text, record) => {
                return (
                    <Space>
                        <Dropdown
                            menu={{
                                items,
                                onClick: async (c) => {
                                    try {
                                        await downloadFile({
                                            id: record.id,
                                            teamId: team?.id,
                                            type: 'invoice',
                                            fileType: c.key,
                                            test: false,
                                        })
                                        try {
                                            posthog.capture('invoice_file_downloaded', {
                                                team: team?.id,
                                            })
                                        } catch (error) {}
                                    } catch (error: any) {
                                        message.error(error?.message ?? 'Hubo un error al descargar el archivo')
                                        posthog.capture('invoice_file_download_error', {
                                            team: team?.id,
                                            error: error?.message ?? 'Error desconocido',
                                        })
                                    }
                                },
                            }}
                            trigger={['click']}
                        >
                            <BsDownload style={{ cursor: 'pointer' }} />
                        </Dropdown>
                        <BsEye
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                window.open(record.verification_url, '_blank')
                            }}
                        />
                    </Space>
                )
            },
        },
    ]

    // const usingData = (invoices ?? [])?.sort((a: any, b: any) => {
    //     return moment(b?.timestamp).valueOf() - moment(a?.timestamp).valueOf()
    // }).filter((p: any) => {
    //     //filter by search in amount, customer name, customer email, customer phone
    //     let amount = p?.total > 0 ? p?.total : p?.total
    //     let formatter = new Intl.NumberFormat('es-MX', {
    //         style: 'currency',
    //         currency: p?.currency?.toUpperCase() ?? 'MXN',
    //     });
    //     let amountFormatted = formatter.format((amount))
    //     let amountFormattedLower = amountFormatted.toLowerCase()
    //     let searchLower = search.toLowerCase()
    //     let date = moment(p?.timestamp).format('D MMM YY HH:mm')
    //     var numberAmount = Number(search);

    //     console.log(onIdFilter);

    //     return amountFormattedLower.includes(searchLower) ||
    //         date.includes(searchLower) ||
    //         (numberAmount > 0 && amount > numberAmount - 100 && amount < numberAmount + 100)
    //     // || (p?.clientId ?? p?.clientID)?.includes(onIdFilter)

    // })

    return (
        <Row>
            <Col xs={24} style={{ borderBottom: '1px solid #D8DAE5', padding: '0px 15px 15px 15px' }}>
                <Row align="middle" justify="space-between">
                    <Typography.Title level={5} style={{ margin: 0 }}>
                        Todas mis facturas
                    </Typography.Title>
                    {/* <Row align="middle">
                        <div style={{ border: '1px solid #D8DAE5', borderRadius: '5px', padding: '1px 15px', cursor: 'pointer' }}>
                            <Row align="middle">
                                {loading.includes('downloadAll') ? <Oval
                                    height={10}
                                    width={10}
                                    color={team?.brand?.secondaryColor ?? "white"}
                                    wrapperStyle={{
                                        marginRight: '5px'
                                    }}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor={team?.brand?.secondaryColor ?? "#cecece"}
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                /> : <AiOutlineDownload style={{
                                    color: '#474D66',
                                    marginRight: '5px'
                                }} />}
                                <Typography.Text style={{
                                    color: '#474D66',
                                }}>Descargar mis facturas</Typography.Text>
                            </Row>
                        </div>
                    </Row> */}

                    <Row style={{ width: '50%' }} align="middle">
                        <Form
                            form={form}
                            style={{ flex: 1 }}
                            onFinish={(v) => {
                                setSearch(v.search ?? '')
                            }}
                        >
                            <Row>
                                <Form.Item
                                    name={'search'}
                                    label={null}
                                    help={null}
                                    style={{ margin: 0, padding: 0, flex: 1 }}
                                >
                                    {/* <Select
                                        onChange={(v) => {
                                            console.log(v);
                                            setOnIdFilter(v)
                                        }}
                                        allowClear
                                        onClear={() => {
                                            setOnIdFilter('')
                                        }}
                                        showSearch
                                        placeholder="Clientes duplicados"
                                        filterOption={(input, option) =>
                                            option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        // searchValue={search}
                                        style={{ flex: 1 }}
                                        options={[...userCtx?.customers?.map((c: any) => {
                                            return {
                                                label: c?.name ?? '',
                                                value: c?.id ?? c,
                                                ...c
                                            }
                                        })]}
                                        optionLabelProp="label"
                                        optionRender={(c) => {
                                            const client = userCtx?.customers?.find((cc: any) => cc?.id === c?.value)
                                            return <div className='d-flex flex-column'>
                                                <Typography.Text>{c.label} </Typography.Text>
                                                <Typography.Text>{client?.id} </Typography.Text>
                                            </div>
                                        }}
                                    /> */}
                                </Form.Item>
                                <Form.Item
                                    name={'search'}
                                    label={null}
                                    help={null}
                                    style={{ margin: 0, padding: 0, flex: 1 }}
                                >
                                    <Input
                                        prefix={<CiSearch />}
                                        onChange={async (v) => {
                                            setSearch(v?.target?.value ?? '')
                                        }}
                                        disabled={false}
                                        placeholder={'Buscar ...'}
                                    />
                                </Form.Item>
                            </Row>
                        </Form>
                        {search !== '' && (
                            <IoCloseOutline
                                style={{ cursor: 'pointer' }}
                                onClick={(_: any) => {
                                    setSearch('')
                                    form.resetFields()
                                }}
                            />
                        )}
                    </Row>
                </Row>
            </Col>
            <Col xs={24}>
                <Table
                    loading={invStatus === 'loading'}
                    columns={columns}
                    dataSource={usingData}
                    pagination={{
                        onChange: (page) => {
                            setCurrentPage(page)
                        },
                        defaultCurrent: currentPage,
                        current: currentPage,
                        pageSize: 10,
                        total: usingData?.length ?? 0,
                        showSizeChanger: false,
                        hideOnSinglePage: true,
                        position: ['bottomRight'],
                    }}
                    locale={{
                        emptyText: (
                            <div className="d-flex flex-column" style={{}}>
                                <Typography.Text style={{ color: '#667085' }}>
                                    No tienes facturas registradas
                                </Typography.Text>
                            </div>
                        ),
                    }}
                    //make the table scrollable
                    scroll={{ x: 500 }}
                    rowKey={(record) => record.id}
                    size="middle"
                />
            </Col>
        </Row>
    )
}

export default InvoicesTable
