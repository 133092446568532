import { ConfigProvider, Form, Input, Layout, Row, Popover, Typography, Button, notification } from 'antd'
import { useEffect, useState } from 'react'
import { ReadableTeamDef } from '../context/teamSlice'
import { setUser } from '../context/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import { LiaKeySolid } from 'react-icons/lia'
import { CiMail } from 'react-icons/ci'
import { PiQuestionThin } from 'react-icons/pi'
import { Oval } from 'react-loader-spinner'
import { BsSendCheck } from 'react-icons/bs'
import ReactInputVerificationCode from 'react-input-verification-code'
import { IoReturnDownBackOutline } from 'react-icons/io5'
import { SignedInternalAPIRequest } from '../functions/apiRequest'
import { usePostHog } from 'posthog-js/react'

interface IOtpTimerProps {
    initSeconds: number
    backFunction: Function
    timerPaused: Function
}

function OTPTimer({ initSeconds, backFunction, timerPaused }: Readonly<IOtpTimerProps>) {
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(initSeconds)

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval)
                    timerPaused(true)
                } else {
                    setSeconds(59)
                    setMinutes(minutes - 1)
                }
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
        //eslint-disable-next-line
    }, [seconds])

    return (
        <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
            {seconds} segundos
        </Typography.Text>
    )
}

function LoginScreen() {
    const { team }: { team: ReadableTeamDef } = useSelector((state: any) => state.team)
    const dispatch = useDispatch()

    const [state, setState] = useState<any>({})
    const [api, contextHolder] = notification.useNotification()
    const [currentStep, setCurrentStep] = useState(0)
    const [loadingElements, setLoadingElements] = useState<string[]>([])

    const [otp, setOtp] = useState<string>('')
    const [initSeconds, setInitSeconds] = useState(0)

    const [notFound, setNotFound] = useState(false)

    const { search } = window.location
    const params = new URLSearchParams(search)

    const sessionId = params.get('sessionId')
    const c = params.get('c')

    const posthog = usePostHog()

    const ValidateFromQuery = async () => {
        // return;
        if (sessionId && c && team.id) {
            await new Promise((resolve) => setTimeout(resolve, 500))
            window.localStorage.setItem(`gigCusPortSessionId-${team.id}`, sessionId)

            setTimeout(() => {
                setInitSeconds(60)
                setLoadingElements(loadingElements.filter((element) => element !== 'email' && element !== 'key'))
                showNotification('email')
                setCurrentStep(1)
            }, 2000)

            await validateOTP(c)
            //remove from url query params after ?
            window.history.replaceState(null, '', window.location.pathname)
        }
    }

    useEffect(() => {
        ValidateFromQuery()
        // eslint-disable-next-line
    }, [team.id])

    const NeedHelp = () => {
        return team?.supportEmail ? (
            <Row justify="end">
                <a href={`mailto:${team?.supportEmail}?subject="Necesito ayuda desde el portal de cliente"`}>
                    {' '}
                    <Typography.Text style={{ fontSize: '11px', color: '#cecece' }}>¿Necesitas ayuda?</Typography.Text>
                </a>
            </Row>
        ) : (
            <></>
        )
    }

    const showNotification = (key: string) => {
        api.open({
            message: (
                <Row align="middle">
                    <Typography.Text style={{ flex: 1, fontWeight: 'bold', fontSize: '12px', paddingRight: '5px' }}>
                        Hemos enviado un código a tu correo electrónico
                    </Typography.Text>
                </Row>
            ),

            closeIcon: (
                <BsSendCheck
                    style={{
                        color: team?.brand?.primaryColor ?? '#00b96b',
                    }}
                />
            ),
            duration: 4.5,
            description: (
                <div className="d-flex flex-column">
                    <Typography.Text style={{ fontSize: '11px' }}>
                        Revisa tu bandeja y copia el código de un solo uso para acceder al portal de cliente.
                    </Typography.Text>
                    <NeedHelp />
                </div>
            ),
            key,
        })
    }

    const EmailStepForm = () => {
        return (
            <Form
                initialValues={state}
                layout="vertical"
                style={{ width: '100%', padding: '0px 13%', marginTop: '30px', marginBottom: '30px' }}
                onFinish={async (values) => {
                    window.localStorage.clear()
                    await new Promise((resolve) => setTimeout(resolve, 600))
                    setLoadingElements([...loadingElements, 'email', 'key'])
                    setState({ ...state, ...values })
                    setNotFound(false)
                    try {
                        try {
                            posthog.capture('request_otp', {
                                email: values?.email,
                                team: team?.id,
                            })
                        } catch (error) {
                            console.log(`Error building posthog event`, error)
                        }

                        const data = await SignedInternalAPIRequest({
                            body: {
                                email: values?.email,
                                team: team?.id,
                            },
                            endPoint: `customerportalapp/v1/auth/requestOTPToken`,
                            extra: {},
                            type: 'POST',
                            teamId: team?.id ?? '',
                        })

                        window.localStorage.setItem(`gigCusPortSessionId-${team.id}`, data.sessionId)

                        setTimeout(() => {
                            setInitSeconds(60)
                            setLoadingElements(
                                loadingElements.filter((element) => element !== 'email' && element !== 'key'),
                            )
                            showNotification('email')
                            setCurrentStep(1)
                        }, 2000)
                    } catch (error: any) {
                        console.log(error?.message)
                        if (error?.error === 'Not customer found') {
                            setNotFound(true)
                            notification.error({
                                message: 'No se encontró un cliente con ese correo electrónico',
                            })
                        }
                        setLoadingElements(
                            loadingElements.filter((element) => element !== 'email' && element !== 'key'),
                        )
                    }
                }}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Ingresa tu correo electrónico',
                        },
                        {
                            type: 'email',
                            message: 'Ingresa un correo electrónico válido',
                        },
                    ]}
                >
                    <Input
                        placeholder="Correo electrónico"
                        prefix={<CiMail />}
                        suffix={
                            <Popover
                                title={
                                    <Row justify="space-between" align="middle">
                                        <Typography.Text style={{ fontSize: '13px', margin: 0 }}>
                                            Correo electrónico
                                        </Typography.Text>
                                        <CiMail />
                                    </Row>
                                }
                                content={
                                    <div className="d-flex flex-column" style={{ maxWidth: '210px' }}>
                                        <Typography.Text style={{ fontSize: '12px', color: '#333' }}>
                                            Enviaremos un código de un solo uso para acceder al portal de cliente.
                                        </Typography.Text>
                                        <NeedHelp />
                                    </div>
                                }
                            >
                                <PiQuestionThin />
                            </Popover>
                        }
                    />
                </Form.Item>
                <Row justify="center">
                    <Button htmlType="submit" loading={loadingElements.includes('email')} type="primary">
                        {loadingElements.includes('email') ? 'Enviando código' : 'Enviar código'}
                    </Button>
                </Row>
            </Form>
        )
    }

    const validateOTP = async (value: string) => {
        try {
            setOtp(value)
            console.log(`***********************************************************`)

            try {
                posthog.capture('try_validate_otp', {
                    email: state?.email ?? '',
                    team: team?.id,
                })
            } catch (error) {
                console.log(`Error building posthog event`, error)
            }

            setLoadingElements([...loadingElements, 'otp'])

            const req = await SignedInternalAPIRequest({
                body: {
                    code: value,
                    sessionId: window.localStorage.getItem(`gigCusPortSessionId-${team.id}`),
                },
                endPoint: `customerportalapp/v1/auth/verifyOTPToken`,
                extra: {},
                type: 'POST',
                teamId: team?.id ?? '',
            })

            window.localStorage.setItem(`gigCusPortData-${team.id}`, JSON.stringify(req.data))
            window.localStorage.setItem(`authToken`, req.data.token)

            dispatch(
                setUser({
                    name: req.data.name ?? '',
                    token: req.data.token,
                    email: req.data.email ?? '',
                    customers: req.data.customers ?? [],
                    user_hash: req.data.intercomHash ?? '',
                }),
            )

            try {
                posthog.identify(req.data.email ?? '', {
                    name: req.data.name ?? '',
                    email: req.data.email ?? '',
                    team: team?.id,
                })
                posthog.capture('validated_otp', {
                    name: req.data.name ?? '',
                    email: req.data.email ?? '',
                    team: team?.id,
                })
            } catch (error) {
                console.log(`Error building posthog event`, error)
            }

            setTimeout(() => {
                setLoadingElements(loadingElements.filter((element) => element !== 'otp'))
            }, 2000)
        } catch (error: any) {
            console.log(error?.message)
            console.log(error)
            notification.error({
                message: error?.message,
            })

            try {
                posthog.capture('error_validate_otp', {
                    error: error?.message ?? '',
                    team: team?.id,
                })
            } catch (error) {
                console.log(`Error building posthog event`, error)
            }
            setLoadingElements(loadingElements.filter((element) => element !== 'otp'))
        }
    }

    const CodeStepForm = () => {
        return (
            <div className="d-flex flex-column" style={{ marginTop: '10px', marginBottom: '30px' }}>
                <div className="custom-styles">
                    <ReactInputVerificationCode
                        length={6}
                        value={otp}
                        onCompleted={async (value) => {
                            if (otp !== value && !value.includes('·')) {
                                validateOTP(value)
                            }
                        }}
                    />
                </div>
                {loadingElements.includes('otp') ? (
                    <Row justify="center" style={{ marginTop: '10px' }}>
                        <Oval
                            height={20}
                            width={20}
                            color={team?.brand?.primaryColor ?? '#4fa94d'}
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor={team?.brand?.secondaryColor ?? '#cecece'}
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </Row>
                ) : initSeconds > 0 ? (
                    <Row justify="center" style={{ marginTop: '10px' }}>
                        <OTPTimer initSeconds={60} timerPaused={() => setInitSeconds(0)} backFunction={() => {}} />
                    </Row>
                ) : (
                    <div className="d-flex flex-column">
                        {otp && (
                            <Button type="primary" style={{ margin: '15px 0px' }} onClick={() => validateOTP(otp)}>
                                Validar código
                            </Button>
                        )}
                        <Row align="middle" justify="center" style={{ marginTop: '10px' }}>
                            <IoReturnDownBackOutline />
                            <Button
                                onClick={() => setCurrentStep(0)}
                                type="link"
                                style={{ fontSize: '12px', color: '#333' }}
                                disabled={initSeconds > 0}
                            >
                                {' '}
                                {initSeconds > 0 ? initSeconds : '¿No recibiste el código?'}
                            </Button>
                        </Row>
                    </div>
                )}
            </div>
        )
    }

    const RenderStep = () => {
        switch (currentStep) {
            case 0:
                return <EmailStepForm />
            case 1:
                return <CodeStepForm />
            default:
                return <EmailStepForm />
        }
    }

    const Content = () => {
        var innerWidth = window.innerWidth
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    width: innerWidth < 790 ? '95%' : '55%',
                    borderRadius: '10px',
                    padding: '15px',
                }}
            >
                <Row align="middle" justify="end" style={{ width: '100%' }}>
                    <img
                        src={team?.brand?.logo ?? ''}
                        alt={team?.brand?.alias ?? ''}
                        height="30px"
                        style={{
                            objectFit: 'contain',
                        }}
                    />
                </Row>
                <div
                    style={{
                        border: `1px solid ${team?.brand?.primaryColor ?? '#cecece'}`,
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '7px',
                        marginTop: '10px',
                    }}
                >
                    {loadingElements.includes('key') ? (
                        <Oval
                            height={20}
                            width={20}
                            color={team?.brand?.primaryColor ?? '#4fa94d'}
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor={team?.brand?.secondaryColor ?? '#cecece'}
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    ) : (
                        <LiaKeySolid style={{ fontSize: '20px', color: team?.brand?.primaryColor ?? '#cecece' }} />
                    )}
                </div>
                <div style={{ marginTop: '10px' }}>
                    <Typography.Title level={5} style={{ margin: 0, marginTop: '5px', padding: 0 }}>
                        Acceso al portal de cliente
                    </Typography.Title>
                </div>
                <div style={{ marginTop: '2px' }}>
                    <Typography.Text type="secondary" style={{ margin: 0, marginTop: '5px', padding: 0 }}>
                        Recupera todos los pagos, facturas, recibos de venta y estados de cuenta.
                    </Typography.Text>
                </div>
                <RenderStep />

                {notFound && (
                    <Row justify="center" style={{ marginTop: '10px' }}>
                        <Typography.Text type="danger" style={{ fontSize: '12px' }}>
                            No se encontró un cliente con ese correo electrónico
                        </Typography.Text>
                    </Row>
                )}

                <Row justify="center">
                    <a
                        href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/privacy.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
                            Consultar aviso de privacidad
                        </Typography.Text>
                    </a>
                </Row>
            </div>
        )
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: team?.brand?.primaryColor ?? '#00b96b',
                    colorLink: team?.brand?.primaryColor ?? '#00b96b',
                    colorText: '#333',
                    colorTextSecondary: '#333',
                },
                components: {},
            }}
        >
            {contextHolder}
            <Layout>
                <Layout.Content
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {team?.id ? (
                        <Content />
                    ) : (
                        <Row>
                            <div
                                className="d-flex flex-column"
                                style={{
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <div className="d-flex flex-row">
                                    <Oval
                                        height={50}
                                        width={50}
                                        color={'#333'}
                                        wrapperStyle={{ padding: '0px' }}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="oval-loading"
                                        secondaryColor={'#cecece'}
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    />
                                </div>
                            </div>
                            {/* <Typography.Text>Cargando..</Typography.Text> */}
                        </Row>
                    )}
                </Layout.Content>
            </Layout>
        </ConfigProvider>
    )
}

export default LoginScreen
